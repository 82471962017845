import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!

  return (_openBlock(), _createBlock(_component_ion_fab, { horizontal: "end" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_fab_button, {
        class: "p-0 fav-button",
        color: "--ion-color-white",
        onClick: _ctx.favour
      }, {
        default: _withCtx(() => [
          (_ctx.isFavorite)
            ? (_openBlock(), _createBlock(_component_ion_icon, {
                key: 0,
                icon: _ctx.star,
                class: "w-5 h-5"
              }, null, 8, ["icon"]))
            : (_openBlock(), _createBlock(_component_ion_icon, {
                key: 1,
                icon: _ctx.starOutline,
                class: "w-5 h-5",
                color: "primary"
              }, null, 8, ["icon"]))
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}