
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle
} from '@ionic/vue'

import FavButton from '../components/FavButton.vue'

import { useRouter } from 'vue-router'
import { computed, defineComponent } from 'vue'

import { settings } from '../settings'

export default defineComponent({
  props: ['id', 'translations', 'icon'],
  components: {
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    FavButton
  },
  setup(props) {
    const router = useRouter()

    const mainTranslation = computed(
      () => props.translations[settings.value.primaryLanguage]
    )

    const secondaryTranslations = computed(() =>
      Object.keys(props.translations)
        .filter((l: string) => settings.value.secondaryLanguages.includes(l))
        .map((l) => props.translations[l])
        .join(', ')
    )

    return { router, mainTranslation, secondaryTranslations }
  }
})
