
import {
  IonSearchbar,
  IonToolbar,
  IonButton,
  IonIcon,
  IonHeader
} from '@ionic/vue'
import { defineComponent } from 'vue'
import { mic } from 'ionicons/icons'
// import { SpeechRecognition } from '@capacitor-community/speech-recognition'
import { SpeechRecognition } from '@awesome-cordova-plugins/speech-recognition'
import { getLanguages } from '../db'
import { settings } from '../settings'
import { isPlatform } from '@ionic/vue'

export default defineComponent({
  components: {
    IonSearchbar,
    IonToolbar,
    IonButton,
    IonIcon,
    IonHeader
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isRecording: false
    }
  },
  computed: {
    speechId() {
      const languages = getLanguages()
      const primaryLanguage = languages.find(
        (language) => language.id === settings.value.primaryLanguage
      )

      if (isPlatform('android')) {
        return primaryLanguage?.speech.android
      }

      if (isPlatform('ios')) {
        return primaryLanguage?.speech.ios
      }

      return null
    }
  },
  methods: {
    async startSpeechRecognition() {
      if (!this.speechId) {
        return
      }

      const available = await SpeechRecognition.isRecognitionAvailable()

      if (!available) {
        return
      }

      const hasPermission = await SpeechRecognition.hasPermission()

      if (!hasPermission) {
        await SpeechRecognition.requestPermission()
        return
      }

      if (this.isRecording) {
        try {
          await SpeechRecognition.stopListening()
        } catch (_) {
          //
        }
        this.isRecording = false
        return
      }

      this.isRecording = true

      const recognitionObserver = SpeechRecognition.startListening({
        language: this.speechId,
        showPartial: true,
        prompt: 'Sag etwas..',
        matches: 1,
        showPopup: true
      })

      recognitionObserver.subscribe(
        (matches: string[]) => {
          this.$emit('update:modelValue', matches[0])

          SpeechRecognition.stopListening().then(
            () => (this.isRecording = false)
          )
        },
        () => {
          this.isRecording = false
        }
      )

      // if (!(await SpeechRecognition.available()).available) {
      //   console.log('not available')
      //   return
      // }
      // console.log('checking permission')
      // if (!(await SpeechRecognition.hasPermission()).permission) {
      //   console.log('no permission')
      //   await SpeechRecognition.requestPermission()
      //   return
      // }
      // if (this.isRecording) {
      //   await SpeechRecognition.stop()
      //   this.isRecording = false
      //   return
      // }
      // this.isRecording = true
      // try {
      //   console.log('start')
      //   const result = await SpeechRecognition.start({
      //     language: 'de-DE',
      //     maxResults: 2,
      //     prompt: 'Sag etwas..',
      //     partialResults: true,
      //     popup: true
      //   })
      //   console.log('end')
      //   if (result.matches.length) {
      //     this.$emit('update:modelValue', result.matches[0])
      //   }
      //   await SpeechRecognition.stop()
      // } catch (e) {
      //   console.log(e)
      //   // Ignore
      // } finally {
      //   this.isRecording = false
      // }
      // try {
      //   const result = await SpeechRecognition.start({
      //     language: 'de-DE',
      //     maxResults: 2,
      //     prompt: 'Sag etwas..',
      //     partialResults: true,
      //     popup: true
      //   })
      //   console.log(result)
      // } catch (_) {
      //   // ignore
      //   console.log('no result')
      // } finally {
      //   this.isRecording = false
      // }
    },
    handleInput(e) {
      this.$emit('update:modelValue', e.target.value)
    }
  },
  setup() {
    return {
      mic
    }
  }
})
