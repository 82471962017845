import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4470411a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex-grow searchbar" }
const _hoisted_3 = {
  key: 0,
  class: "flex-none audio"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ion_searchbar, {
                onIonChange: _ctx.handleInput,
                debounce: "250",
                value: _ctx.modelValue,
                placeholder: "Suche",
                autocomplete: "on"
              }, null, 8, ["onIonChange", "value"])
            ]),
            (_ctx.speechId)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_ion_button, {
                    class: "p-0",
                    fill: "clear",
                    onClick: _ctx.startSpeechRecognition
                  }, {
                    default: _withCtx(() => [
                      (_ctx.isRecording)
                        ? (_openBlock(), _createBlock(_component_ion_icon, {
                            key: 0,
                            class: "text-secondary",
                            icon: _ctx.mic
                          }, null, 8, ["icon"]))
                        : (_openBlock(), _createBlock(_component_ion_icon, {
                            key: 1,
                            icon: _ctx.mic
                          }, null, 8, ["icon"]))
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}