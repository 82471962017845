
import { IonIcon, IonFab, IonFabButton } from '@ionic/vue'
import { star, starOutline } from 'ionicons/icons'
import { settings, updateSettings } from '@/settings'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: ['vocabId'],
  components: { IonIcon, IonFab, IonFabButton },
  setup(props) {
    const isFavorite = computed(() =>
      settings.value.favorites.includes(props.vocabId)
    )

    function favour(e: Event) {
      e.preventDefault()

      const favs = settings.value.favorites

      if (isFavorite.value) {
        updateSettings({ favorites: favs.filter((id) => id !== props.vocabId) })
      } else {
        updateSettings({ favorites: [...favs, props.vocabId] })
      }
    }

    return {
      star,
      starOutline,
      isFavorite,
      favour
    }
  }
})
