import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e16ee4ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "w-16" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "grow w-full min-w-0" }
const _hoisted_5 = { class: "flex-none flex w-12 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_FavButton = _resolveComponent("FavButton")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    class: "m-1 mb-2 py-2 px-2",
    button: true,
    "router-link": `/vokabel/${_ctx.id}`,
    "router-direction": "forward"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.icon || `/assets/icon/vocab/${_ctx.id}.svg`,
            width: "62"
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ion_card_header, { class: "p-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card_title, { class: "text-base truncate" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.mainTranslation), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card_subtitle, { class: "text-xs truncate" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.secondaryTranslations), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_FavButton, { vocabId: _ctx.id }, null, 8, ["vocabId"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["router-link"]))
}