<template>
  <ion-page>
    <SearchBar v-model="search" />
    <div class="relative w-full h-full">
      <Search v-if="search.length" :search="search" />
      <ion-router-outlet v-else />
    </div>
  </ion-page>
</template>

<script>
import { IonPage, IonRouterOutlet } from '@ionic/vue'
import SearchBar from './components/SearchBar.vue'
import Search from './views/Search.vue'

export default {
  components: {
    IonPage,
    IonRouterOutlet,
    SearchBar,
    Search
  },
  data() {
    return {
      search: ''
    }
  },
  watch: {
    $route() {
      this.search = ''
    }
  }
}
</script>
