<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <p class="p-3 text-lg text-center" v-if="results.length === 0">
        Es konnten keine passenden Vokabeln gefunden werden.
      </p>
      <ion-list>
        <VocabTile
          v-for="vocab in results"
          :icon="vocab.icon"
          :key="vocab.id"
          :id="vocab.id"
          :translations="vocab.translations"
        />
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonList } from '@ionic/vue'
import VocabTile from '../components/VocabTile.vue'
import { findVocabs } from '../db'

export default {
  name: 'Search',
  components: {
    IonPage,
    IonContent,
    IonList,
    VocabTile
  },
  props: {
    search: {
      type: String,
      default: ''
    }
  },
  computed: {
    results() {
      return findVocabs(this.search)
    }
  }
}
</script>
